<template>
  <header class="header" :class="{ 'header--min': navVisible }">
    <span class="headline">
      <router-link to="/">We love Kulturerbe</router-link>
    </span>
    <span class="loading" v-if="loadingCount"></span>
    <div class="logo">
      <HeaderLogo />
      <router-link to="/">
        SKKG
        <br />
        Stiftung für Kunst, Kultur und Geschichte
      </router-link>
    </div>
    <div class="nav">
      <nav class="nav-main">
        <ul>
          <li>
            <a href="#" @click.prevent="$store.commit('toggleNav')">Menu</a>
          </li>
          <li>
            <router-link to="/pages/contact">Kontakt & Newsletter</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="lang">
      <ul class="nav-lang">
        <li
          v-for="(lang, i) in langs"
          :key="`Lang${i}`"
          :class="{ active: langIsActive(lang) }"
        >
          <a href="#" @click.prevent="setLang(lang)">{{ lang.substr(0, 1) }}</a>
        </li>
      </ul>
    </div>
    <button
      @click="$store.commit('closeNav')"
      class="header__closenav"
    ></button>
  </header>
</template>

<script>
import HeaderLogo from '@/components/HeaderLogo';

export default {
  name: 'Header',
  components: {
    HeaderLogo,
  },
  computed: {
    langs() {
      return this.$store.state.langs;
    },
    activeLang() {
      return this.$store.state.activeLang;
    },
    subpages() {
      return this.$store.state.subpages;
    },
    loadingCount() {
      return this.$store.state.loadingCount;
    },
    navVisible() {
      return this.$store.state.navVisible;
    },
  },
  mounted() {},
  methods: {
    setLang(lang) {
      let _lang = lang;
      this.$store.dispatch('changeLanguage', {
        lang: _lang,
        i18n: this.$i18n,
      });
    },
    langIsActive(lang) {
      return lang == this.activeLang;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  background: #fff;
  color: #000;
  padding: 0.7rem 0.75rem;
  display: flex;
  box-sizing: border-box;
  line-height: 1rem;
  height: var(--header-height);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  line-height: 1.1;

  @include bp-s() {
    height: 2rem;
    padding: 0 0.5rem;
    z-index: 10;
  }

  &--min {
    .nav,
    .nav-lang {
      opacity: 0;
      pointer-events: none;
    }
    .header__closenav {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &__closenav {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    appearance: none;
    border: none;
    background: none;
    background-image: url('../assets/icon-faq-close.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;

    @include bp-s() {
      display: none;
    }
  }
}

.loading {
  position: absolute;
  top: 2rem;
  left: 0.75rem;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  border: 1px solid #8f8f8f;
  border-left-color: #bbb;
  border-top-color: #ddd;
  border-right-color: #fff;
  animation: 1s loader infinite linear;
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headline {
  flex-basis: 20%;

  @include bp-s() {
    flex-basis: auto;
    line-height: var(--header-height);
  }
}
.logo {
  position: relative;
  flex-grow: 1;
  // flex-basis: 50%;
  padding-right: 0.5rem;
  padding-left: 3.4rem;
  white-space: nowrap;
  display: flex;
  // align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include bp-s() {
    display: none;
  }
}

.nav {
  flex-basis: 20%;

  @include bp-s() {
    display: none;
  }
}
.nav-main {
  ul {
    // display: flex;

    li {
      white-space: nowrap;
    }
    a {
      &:hover,
      &.router-link-active {
        text-decoration: underline;
      }
    }
  }
}
.lang {
  // flex-basis: 5rem;
  flex-grow: 0;
}
.nav-lang {
  display: flex;
  justify-content: flex-end;
  // flex-shrink: 0;

  li {
    margin-left: 0.25rem;
    text-transform: uppercase;
    white-space: nowrap;

    &.active a {
      text-decoration: underline;
    }
    &::after {
      content: ' / ';
    }
    &:last-child::after {
      content: '';
    }
  }

  @include bp-s() {
    display: none;
  }
}
</style>
