<template>
  <div
    class="hovergif"
    :class="{ 'hovergif--active': animating }"
    @mouseover="animating = true"
    @mouseout="animating = false"
  >
    <img class="hovergif__image" v-if="gif" :src="gif.url" />
    <canvas class="hovergif__canvas" ref="canv"></canvas>
  </div>
</template>

<script>
export default {
  props: ['gif'],
  data: () => {
    return {
      animating: false,
    };
  },
  mounted() {
    this.initCanvas();
  },
  methods: {
    initCanvas() {
      const canvas = this.$refs.canv;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = this.gif.url;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.hovergif {
  // position: relative;

  &__image {
    width: 100%;
    height: auto;
    opacity: 0;
  }
  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &--active {
    .hovergif__canvas {
      display: none;
    }
    .hovergif__image {
      opacity: 1;
    }
  }
}
</style>