<i18n>
{
  "de": {
    "more": "Mehr",
    "about": "Über die Stiftung",
    "subscribe": "Newsletter abonnieren"
  },
  "fr": {
    "more": "plus"
  }
}
</i18n>

<template>
  <section class="home-hero" :style="{ backgroundColor: mainStory.color }">
    <div class="home-hero__mainstory">
      <small class="home-hero__cat">{{ mainStory.subtitle }}</small>
      <h2 class="font-xxl">{{ mainStory.leadTitle }}</h2>
      <div class="home-hero__animationwrap" v-if="mainStory.animation">
        <img :src="mainStory.animation.url" />
      </div>
      <p class="lead">{{ mainStory.lead }}</p>
      <router-link
        :to="'stories/' + mainStory.slug"
        class="home-hero__mainstory__btn"
      >
        {{ moreText }}
      </router-link>
    </div>
    <div class="home-hero__stories" v-if="home.hero.linkedStories.length > 0">
      <ul>
        <li v-for="story in home.hero.linkedStories" :key="story.id">
          <router-link :to="'stories/' + story.slug">
            <img
              class="home-hero__stories__img"
              :src="story.previewImage.url"
            />
            <small class="home-hero__cat">{{ story.subtitle }}</small>
            <h2>{{ story.leadTitle }}</h2>
          </router-link>
        </li>
      </ul>
      <a href="/stories" class="link-arrow">Alles anzeigen</a>
    </div>
  </section>
</template>

<script>
export default {
  props: ['home'],
  computed: {
    mainStory() {
      return this.home.hero.mainStory[0];
    },
    moreText() {
      return this.mainStory.moreButtonText
        ? this.mainStory.moreButtonText
        : this.$t('more');
    },
  },
};
</script>

<style lang="scss">
.home-hero {
  min-height: 800px;
  height: 85vh;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;

  @include bp-s() {
    flex-wrap: wrap;
    min-height: 0;
    height: auto;
  }

  &__cat {
    display: block;
    font-size: 0.6rem;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    // margin-bottom: 5px;
  }
  &__animationwrap {
    position: absolute;
    bottom: 1.5rem;
    right: 25%;
    width: 45%;
    // max-width: 50rem;
    pointer-events: none;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    @include bp-s() {
      position: static;
      right: 0;
      width: 80%;
      margin: 0.5rem auto;
      // margin-top: -1rem;
    }
  }
  &__mainstory {
    width: 65vw;

    h2 {
      hyphens: auto;
      margin-top: 12px;
      position: relative;
      z-index: 1;
    }
    p.lead {
      max-width: 44vw;
      font-size: 1rem;
      margin: 9px 0 18px 0;
      position: relative;
      z-index: 1;

      @include bp-l() {
        max-width: 800px;
      }
      @include bp-s() {
        max-width: 100%;
      }
    }
    &__btn {
      display: inline-block;
      background-color: rgba(#fff, 0.5);
      padding: 0.5rem 0.75rem;
    }

    @include bp-s() {
      width: 100%;
      max-width: 550px;
    }
  }
  &__stories {
    width: 25vw;

    li {
      background-color: #fff;
      border: 2px solid #000;
      padding: 9px;
      margin-bottom: 18px;
      position: relative;

      h2 {
        font-weight: 900;
        font-size: 1.3rem;
        hyphens: auto;
        margin-top: 6px;
      }
      a {
        &:hover {
          h2 {
            text-decoration: underline;
          }
          .home-hero__stories__img {
            opacity: 1;
          }
        }
      }
    }
    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      pointer-events: none;
      filter: grayscale(100%);
    }
    @include bp-s() {
      width: 100%;
      max-width: 400px;
      margin-top: 2rem;
    }
  }
}
</style>