<i18n>
{
  "de": {
    "knowmore": "Mehr erfahren",
    "patience": "Geduld, Geduld…",
    "nextepisode": "Die nächste Episode geht am {date} online."
  },
  "fr": {
    "knowmore": "En savoir plus",
    "patience": "Patience, patience",
    "nextepisode": "Le prochain épisode sera mis en ligne le {date}"
  }
}
</i18n>

<template>
  <transition :name="transitionName" :duration="1000">
    <section :key="episode.nr" class="episode-hero" :style="colorVars">
      <div class="episode-hero__content">
        <span class="episode-num">{{ episode.nr }}</span>
        <h1 v-if="isActive">{{ episode.headline }}</h1>
        <h1 v-else>
          {{ episode.previewText || $t('patience') }}
          <br />
          <br />
          {{ $t('nextepisode', { date: postDate }) }}
        </h1>
        <img
          v-if="episode.animation && isActive"
          class="episode-hero__mobileanim"
          :src="episode.animation.url"
        />
        <p v-if="isActive" class="lead">{{ episode.leadText }}</p>
        <a
          v-if="isActive"
          class="episode-hero__morelink"
          href="#"
          @click.prevent="scrollToInfo"
        >
          {{ knowMoreText }}
        </a>
      </div>
      <a
        v-if="episode.promo.title && isActive"
        class="episode-hero__promo"
        href="#promo"
        :style="{ backgroundColor: episode.colorSecondary }"
      >
        <span>{{ episode.promo.title }}</span>
      </a>
      <div
        class="episode-hero__animationwrap"
        v-if="episode.animation && isActive"
      >
        <img :src="episode.animation.url" />
      </div>
      <img
        v-if="!isActive"
        src="../assets/episode-arrow.gif"
        class="episode-hero__arrow"
      />
    </section>
  </transition>
</template>

<script>
import SmoothScroll from 'smooth-scroll';

export default {
  name: 'EpisodeHero',
  props: ['episode'],
  computed: {
    isActive() {
      if (this.$store.state.isPreview) return true;
      return this.episode.status == 'live';
    },
    postDate() {
      let d = new Date(this.episode.postDate * 1000);
      return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();
    },
    transitionName() {
      return this.$store.state.transitionName;
    },
    colorVars() {
      return {
        '--color-active': this.episode.color,
        '--color-bg': this.episode.colorSecondary,
        '--color-dark': this.episode.colorDark,
      };
    },
    knowMoreText() {
      if (this.episode.moreButtonText) return this.episode.moreButtonText;
      return this.$t('knowmore');
    },
  },
  methods: {
    scrollToInfo() {
      let scroll = new SmoothScroll();
      scroll.animateScroll(document.querySelector('#info'), null, {
        offset: 150,
        speed: 800,
      });
    },
  },
};
</script>

<style scoped lang="scss">


.episode-hero {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0.5rem 0.8rem;
  box-sizing: border-box;
  background-color: var(--color-active);
  z-index: 1;

  .episode-num {
    display: block;
    font-size: 0.7rem;
  }
  &__content {
    position: relative;
    z-index: 1;
  }
  &__arrow {
    position: absolute;
    right: 3rem;
    top: 1rem;
    width: 40%;

    @include bp-s() {
      display: none;
    }
  }
  &__morelink {
    display: inline-block;
    background-color: var(--color-bg);
    font-size: 1rem;
    padding: 0.5rem 1rem 0.45rem 1rem;
    box-shadow: 0 0 5px 0 rgba(#fff, 0);
    position: relative;
    left: 0.2rem;
    transition: 0.4s box-shadow;

    &:hover {
      box-shadow: 0 0 15px 0px var(--color-dark);
    }
  }
  &__animationwrap {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 50%;
    // max-width: 50rem;
    pointer-events: none;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    @include bp-s() {
      display: none;
    }
  }
  &__mobileanim {
    display: none;
    width: auto;
    max-width: 100%;
    max-height: 25rem;

    @include bp-s() {
      display: block;
    }
  }
  &__promo {
    display: flex;
    align-items: center;
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 8.7rem;
    height: 8.7rem;
    padding: 1vw;
    background-color: #fff;
    border-radius: 99%;
    text-align: center;
    box-sizing: border-box;
    line-height: 1.2;
    z-index: 1;
    text-decoration-color: #fff;
    transition: 0.2s transform $easeOut, 0.6s box-shadow;
    box-shadow: 0 0 5px 0 rgba(#fff, 0);

    &:hover {
      box-shadow: 0 0 20px 0px var(--color-dark);
    }

    span {
      font-size: 1rem;
      display: block;
      // hyphens: auto;
      width: 100%;
      // padding: .1vw;
    }
  }
}
@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
h1 {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  max-width: 59.5vw;
  position: relative;
  // left: -.4vw;
  margin-top: 0.5rem;

  @include bp-l() {
    max-width: 800px;
  }
  @media screen and (max-height: 800px) {
    font-size: 3rem;
  }
}
p.lead {
  max-width: 44vw;
  font-size: 1rem;
  margin: 0.25rem 0.2rem 0.75rem 0.2rem;

  @include bp-l() {
    max-width: 800px;
  }
}

/*@include bp-m() {
  h1 {
    // font-size: 2.5rem;
    // max-width: 30rem;
  }
  p.lead {
    // font-size: 1rem;
    // max-width: 30rem;
  }
  .episode-hero {
    &__morelink {
      // font-size: 1rem;
    }
    &__promo {
      width: 6rem;
      height: 6rem;

      span {
        font-size: .8rem;
      }
    }
  }

}*/
@include bp-s() {
  .episode-hero {
    height: 100%;
    padding: 0.5rem;

    &__content {
      padding-bottom: 2rem;
    }
    &__animationwrap {
      width: 80%;
    }
    &__morelink {
      // display: none;
      margin: 0;
      left: 0;
      margin-top: 0.5rem;
    }
    &__promo {
      position: absolute;
      width: 100%;
      height: auto;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      background: #fff !important;
      border-radius: 0;
      padding: 0.5rem 0;
      overflow: hidden;

      span {
        display: block;
        width: auto;
        font-size: 1rem;
        white-space: nowrap;
        text-transform: uppercase;
        padding: 0 1rem;
        position: relative;
        top: 0.05rem;
        animation: marquee 10s linear infinite;
      }
    }
  }
  .episode-num {
    font-size: 1rem;
    margin-left: 0.1rem;
  }
  h1 {
    font-size: 1.8rem;
    max-width: 20rem;
  }
  p.lead {
    font-size: 1rem;
    margin: 0;
    max-width: none;
  }
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.8s $easeInOut;

  .episode-hero__content,
  .episode-hero__animationwrap,
  .episode-hero__promo {
    transition: transform 1s $easeInOut;
  }
}
.slide-right-enter, .slide-left-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);

  .episode-hero__content,
  .episode-hero__animationwrap,
  .episode-hero__promo {
    transform: translateX(100%);
  }
}
.slide-left-enter, .slide-right-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100%);

  .episode-hero__content,
  .episode-hero__animationwrap,
  .episode-hero__promo {
    transform: translateX(-100%);
  }
}
</style>
