export default [
  {
    path: '/foerderung',
    redirect: {
      name: 'Subpage',
      hash: '#foerderung',
      params: { slug: 'about' }
    }
  },
  {
    path: '/team',
    redirect: { name: 'Subpage', hash: '#team', params: { slug: 'about' } }
  },
  {
    path: '/sammlung',
    redirect: {
      name: 'Subpage',
      hash: '#sammlung',
      params: { slug: 'about' }
    }
  },
  { path: '/about', redirect: '/pages/about' },
  { path: '/datenschutz', redirect: '/content/datenschutz' },
  {
    path: '/impressum',
    redirect: {
      name: 'Subpage',
      hash: '#impressum',
      params: { slug: 'contact' }
    }
  },
  { path: '/kontakt', redirect: '/pages/contact' },
  { path: '/exhibition', redirect: '/' },
  { path: '/campo', redirect: '/content/campo' },
  { path: '/tako', redirect: '/content/zusammen-neu-denken' }
];
