<template>
  <a
    href="#"
    :class="{ 'mm-toggle': true, active: navVisible }"
    @click.prevent="$store.commit('toggleNav')"
  >
    <span>Toggle Menu</span>
  </a>
</template>

<script>
export default {
  name: 'MobileMenuToggle',
  computed: {
    mobileMenuVisible() {
      return this.$store.state.mobileMenuVisible;
    },
    navVisible() {
      return this.$store.state.navVisible;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.mm-toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 5rem;
  height: 5rem;
  border-radius: 99%;
  box-sizing: border-box;
  z-index: 11;
  font-size: 0.8rem;
  text-align: center;
  display: none;
  align-items: center;
  background-image: url('../assets/skkg-logo.svg');
  background-size: 100%;
  background-repeat: no-repeat;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../assets/menu-burger.svg');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &.active::before {
    background-image: url('../assets/menu-close.svg');
  }

  span {
    display: none;
  }

  @include bp-s() {
    display: flex;
  }
}
</style>
