<i18n>
{
  "de": {
    "fordergeld": "Förderung beantragen"
  },
  "fr": {
    "fordergeld": "Solliciter des fonds"
  }
}
</i18n>

<template>
  <div :class="episodeWrapClasses" v-if="activeEpisode">
    <div class="mobile-headline">
      <h1>
        Stiftung
        <br />
        für Kunst, Kultur und Geschichte
      </h1>
    </div>
    <a
      href="#"
      v-if="globalData"
      :class="{ 'hero-faqtoggle': true, 'hero-faqtoggle--active': faqVisible }"
      @click.prevent="toggleFaq"
    >
      <span>Toggle FAQ</span>
    </a>
    <transition name="faqtransition">
      <div
        class="hero__faq"
        v-if="faqVisible"
        v-html="globalData.faqText"
      ></div>
    </transition>
    <div class="hero-outter">
      <div :class="heroWrapClasses">
        <div class="hero-btns">
          <a class="btn" href="https://foerderung.skkg.ch" target="_blank">
            {{ $t('fordergeld') }}
          </a>
          <ShareButton>Share</ShareButton>
        </div>
        <EpisodeHero :episode="activeEpisode" />
      </div>
      <transition name="numlink-fade">
        <router-link
          :to="'/episodes/' + previousEpisode.nr"
          v-if="previousEpisode"
          @click.prevent="setEpisode(previousEpisode)"
          class="episode-switch episode-switch--prev"
          :style="{ backgroundColor: previousEpisode.color }"
        >
          <transition mode="out-in" name="num-fade">
            <span :key="previousEpisode.nr">{{ previousEpisode.nr }}</span>
          </transition>
        </router-link>
      </transition>
      <transition name="numlink-fade">
        <router-link
          :to="'/episodes/' + nextEpisode.nr"
          v-if="nextEpisode"
          @click.prevent="setEpisode(nextEpisode)"
          class="episode-switch episode-switch--next"
          :style="{ backgroundColor: nextEpisode.color }"
        >
          <transition mode="out-in" name="num-fade">
            <span :key="nextEpisode.nr">{{ nextEpisode.nr }}</span>
          </transition>
        </router-link>
      </transition>
    </div>
    <div :class="infoWrapClasses">
      <EpisodeInfo v-if="episodeIsActive" :episode="activeEpisode" />
    </div>
  </div>
</template>

<script>
import EpisodeHero from '@/components/EpisodeHero';
import EpisodeInfo from '@/components/EpisodeInfo';
import ShareButton from '@/components/ShareButton';

export default {
  name: 'EpisodeWrap',
  data: () => {
    return {
      activeEpisode: null,
      faqVisible: false,
    };
  },
  components: {
    EpisodeHero,
    EpisodeInfo,
    ShareButton,
  },
  watch: {
    episodes: function () {
      if (!this.activeEpisode) {
        this.setFirstEpisode();
      } else if (this.$route.params.nr) {
        this.setEpisodeByNr(this.$route.params.nr);
      }
      this.updateFavicon();
    },
    $route: function () {
      this.faqVisible = false;

      if (this.$route.params.nr) {
        this.setEpisodeByNr(this.$route.params.nr);
      } else if (this.$route.name == 'FirstEpisode') {
        this.setFirstEpisode();
      }
      this.updateFavicon();
    },
    activeLang: function (to, from) {
      this.setEpisodeByNr(this.$route.params.nr);
    },
  },
  beforeRouteUpdate(to, from, next) {
    let trans = 'slide-right';
    if (from.params.nr > to.params.nr) {
      trans = 'slide-left';
    }
    this.$store.commit('setTransitionName', trans);

    next();
  },
  methods: {
    setEpisodeByNr(nr) {
      let ep = this.episodes.find((ep) => {
        return ep.nr == nr;
      });
      this.setEpisode(ep);
    },
    setEpisode(ep) {
      this.activeEpisode = ep;
    },
    setFirstEpisode() {
      if (this.$route.params.nr) {
        this.setEpisodeByNr(this.$route.params.nr);
      } else {
        let ep = this.episodes
          .slice()
          .reverse()
          .find((e) => {
            return e.status == 'live';
          });
        this.$router.replace('/episodes/' + ep.nr);
        this.setEpisode(ep);
      }
    },
    toggleFaq() {
      this.faqVisible = !this.faqVisible;
    },
    updateFavicon() {
      if (!this.activeEpisode) return;
      if (!this.activeEpisode.favicon) return;
      this.$store.commit('setFaviconUrl', this.activeEpisode.favicon.url);
    },
  },
  computed: {
    activeLang() {
      return this.$store.state.activeLang;
    },
    episodes() {
      return this.$store.state.episodes;
    },
    globalData() {
      return this.$store.state.globalData;
    },
    nextEpisode() {
      if (this.episodes[this.episodeIndex + 1]) {
        return this.episodes[this.episodeIndex + 1];
      }
      return null;
    },
    previousEpisode() {
      if (this.episodes[this.episodeIndex - 1]) {
        return this.episodes[this.episodeIndex - 1];
      }
      return null;
    },
    episodeIndex() {
      return this.episodes.indexOf(this.activeEpisode);
    },
    heroWrapClasses() {
      let c = ['hero-wrap'];

      if (this.isFirstEpisode) {
        c.push('hero-wrap--first');
      } else if (this.isLastEpisode) {
        c.push('hero-wrap--last');
      }

      return c;
    },
    episodeWrapClasses() {
      let c = ['episode-wrap'];

      if (this.isFirstEpisode) {
        c.push('episode-wrap--first');
      } else if (this.isLastEpisode) {
        c.push('episode-wrap--last');
      }

      return c;
    },
    infoWrapClasses() {
      let c = ['info-wrap'];

      if (this.isFirstEpisode) {
        c.push('info-wrap--first');
      } else if (this.isLastEpisode) {
        c.push('info-wrap--last');
      }

      return c;
    },
    heroWrapStyle() {
      let st = {};
      // st.width = '100%';
      if (this.isFirstEpisode) {
        st.width = 'calc(100% - 2rem)';
        st.left = 0;
      } else if (this.isLastEpisode) {
        st.width = 'calc(100% - 2rem)';
        st.left = '2rem';
      } else {
        st.width = 'calc(100% - 4rem)';
        st.left = '2rem';
      }
      return st;
    },
    isLastEpisode() {
      return this.episodeIndex == this.episodes.length - 1;
    },
    isFirstEpisode() {
      return this.episodeIndex == 0;
    },
    episodeIsActive() {
      if (this.$store.state.isPreview) return true;
      return this.activeEpisode.status == 'live';
    },
  },
  mounted() {
    if (!this.activeEpisode && this.episodes.length > 0) this.setFirstEpisode();

    window.addEventListener('scroll', (e) => {
      // this.faqVisible = false;
    });

    this.updateFavicon();
  },
};
</script>

<style lang="scss">


.episode-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.hero-outter {
  position: relative;
  height: auto;
}
.hero-wrap {
  position: fixed;
  top: var(--header-height);
  left: 0;
  transform: translateX(2rem);
  height: calc(100% - var(--header-height));
  width: calc(100% - 4rem);
  z-index: 1;
  transition: 0.8s transform $easeInOut, 0.8s width $easeInOut;

  &--first {
    width: calc(100% - 2rem);
    transform: translateX(0);
  }
  &--last {
    width: calc(100% - 2rem);
    transform: translateX(2rem);
  }

  @include bp-s() {
    position: relative;
    height: auto;
    height: 100vh;
    min-height: 39rem;
    top: 0;
    z-index: 4;
  }
}
.info-wrap {
  // transform: translateX(2rem);
  margin-left: 2rem;
  width: calc(100% - 4rem);

  &--first {
    width: calc(100% - 2rem);
    margin-left: 0;
  }
  &--last {
    width: calc(100% - 2rem);
    margin-left: 2rem;
  }
  @include bp-s() {
    width: 100%;
    margin-left: 0;
  }
}
.hero-btns {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 4;
  width: 9rem;

  @include bp-s() {
    display: none;
  }

  .btn {
    transition: 0.5s box-shadow;
    box-shadow: 0 0 15px 2px rgba(#fff, 0);

    &:hover {
      box-shadow: 0 0 15px 2px rgba(#fff, 0.8);
      text-decoration: none;
    }
  }
}
.hero-faqtoggle {
  display: block;
  position: fixed;
  top: calc(100vh - 3.5rem);
  right: 3rem;
  background: #fff;
  background-image: url('../assets/icon-faq-open.svg');
  background-size: 100%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 7;
  text-align: center;
  border-radius: 99%;
  transition: 0.5s box-shadow, 1s transform $easeInOut;
  box-shadow: 0 0 15px 2px rgba(#fff, 0);

  &:hover {
    box-shadow: 0 0 15px 2px rgba(#fff, 0.8);
  }

  span {
    display: none;
  }
  &--active {
    background-image: url('../assets/icon-faq-close.svg');
  }
  .episode-wrap--last & {
    transform: translateX(2rem);
  }

  @include bp-s() {
    // position: fixed;
    top: auto;
    bottom: 1rem;
    left: auto;
    right: 1rem;

    .hero-wrap--last & {
      right: 1rem;
    }
    .episode-wrap--first &, .episode-wrap--last & {
      transform: none;
    }
  }
}
.hero__faq {
  position: fixed;
  bottom: 2.5rem;
  right: 4.5rem;
  max-width: 30rem;
  max-height: calc(100vh - 3.5rem - var(--header-height));
  overflow-y: scroll;
  padding: 0.75rem;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 6;
  transform-origin: 100% 100%;

  h3 {
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    margin-bottom: 0.15rem;
  }
  p + h3,
  p + p {
    margin-top: 1rem;
  }
  strong {
    font-weight: bold;
  }
  .episode-wrap--first & {
    transform: translateX(-2rem);
  }

  @include bp-s() {
    width: calc(100% - 1rem);
    max-height: calc(100vh - 7rem - var(--header-height));
    // left: -1.6rem;
    left: 0.5rem;
    bottom: 2.2rem;
    font-size: 0.8rem;
    transform-origin: 100% 100%;

    .episode-wrap--first & {
      transform: none;
    }
  }
}
.episode-switch {
  position: fixed;
  // z-index: 3;
  top: var(--header-height);
  left: 0;
  height: calc(100% - var(--header-height));
  width: calc(2rem + 1px);
  z-index: 3;
  transition: 0.5s background-color;

  &::before {
    content: '';
    display: block;
    width: 0.8rem;
    height: 1.5rem;
    background-image: url('../assets/icon-narrow-arrow.svg');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.4rem;
    margin-top: -0.75rem;
    transform: rotate(180deg);
    transition: 0.5s transform $easeOut;
  }

  &:hover {
    &::before {
      transform: rotate(180deg) translateX(5px);
    }
  }
  &--next {
    left: auto;
    right: 0;

    &::before {
      transform: rotate(0deg);
    }
    &:hover {
      &::before {
        transform: rotate(0deg) translate(5px);
      }
    }
  }

  span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
    padding-top: 0.5rem;
  }

  @include bp-s() {
    position: absolute;
    bottom: 0;
    top: auto;
    height: 100%;

    &::before {
      display: none;
    }
  }
}

.mobile-headline {
  display: none;
  padding: 2rem 1.5rem 0.5rem 0.5rem;
  max-width: 280px;
  position: relative;
  z-index: 2;
  background: #fff;

  @include bp-s() {
    display: block;
  }

  h1 {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.num-fade-enter-active,
.num-fade-leave-active {
  transition: opacity 0.5s;
}
.num-fade-enter, .num-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.numlink-fade-enter-active,
.numlink-fade-leave-active {
  transition: opacity 0.3s;
}
.numlink-fade-enter-active {
  transition-delay: 0.3s;
}
.numlink-fade-enter, .numlink-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.faqtransition-enter-active {
  transition: opacity 0.3s, transform 0.3s $easeOut;
}
.faqtransition-leave-active {
  transition: opacity 0.3s, transform 0.3s $easeIn;
}
.faqtransition-enter, .faqtransition-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0.5);
}
</style>
