<template>
  <footer class="footer" v-if="globalData">
    <div class="footer__col" v-html="globalData.footer.leftColumn"></div>
    <div class="footer__col">
      <a
        href="https://www.instagram.com/skkg_winterthur"
        target="_blank"
        class="footer__social footer__social--insta"
      ></a>
      <a
        href="https://www.linkedin.com/company/skkg"
        target="_blank"
        class="footer__social footer__social--linkedin"
      ></a>
    </div>
    <div class="footer__col" v-html="globalData.footer.rightColumn"></div>
  </footer>
</template>

<script>
export default {
  computed: {
    globalData() {
      return this.$store.state.globalData;
    },
  },
};
</script>

<style lang="scss">
.footer {
  background: #000;
  color: #fff;
  padding: 1rem 0;
  padding-bottom: 3rem;
  display: flex;

  &__col {
    padding: 0 1rem;
    flex-grow: 0;

    &:first-child {
      flex-grow: 1;
    }
    a {
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 2px;
      }
    }
  }

  p {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }
  &__social {
    display: inline-block;
    width: 2.25rem;
    height: 2.25rem;
    background-size: 100%;
    margin: 0 0.25rem;

    &:hover {
      filter: invert(1);
    }
    &--insta {
      background-image: url('../../assets/share-home-insta.svg');
    }
    &--linkedin {
      background-image: url('../../assets/share-home-linkedin.svg');
    }
  }

  @include bp-s() {
    flex-wrap: wrap;
    padding-bottom: 2rem;

    &__col {
      margin-top: 2rem;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>