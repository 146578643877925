import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import EpisodeWrap from '../views/EpisodeWrap.vue';
import Stories from '../views/Stories.vue';
import NotFoundComponent from '../views/NotFoundComponent.vue';
import Infoanlass from '../views/Infoanlass.vue';
import redirects from './redirects';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/episodes/:nr/',
    name: 'Episode',
    component: EpisodeWrap
  },
  {
    path: '/pages/:slug',
    name: 'Subpage',
    component: () =>
      import(/* webpackChunkName: "subpage" */ '../views/Subpage.vue')
  },
  {
    path: '/content/:slug',
    name: 'Contentpage',
    component: () =>
      import(/* webpackChunkName: "contentpage" */ '../views/Contentpage.vue')
  },
  {
    path: '/stories/:slug',
    name: 'Story',
    component: () =>
      import(/* webpackChunkName: "contentpage" */ '../views/Contentpage.vue')
  },
  { path: '/stories', component: Stories },
  { path: '/infoanlass', component: Infoanlass },
  { path: '*', component: NotFoundComponent, name: '404' }
];

const allRoutes = redirects.concat(routes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allRoutes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
