<template>
  <div class="stories">
    <ul class="stories__list">
      <li
        v-for="story in stories"
        :key="story.id"
        :class="{ topstory: story.topStory }"
        :style="{
          backgroundColor:
            story.topStory ? story.color : '',
        }"
      >
        <router-link :to="`/stories/${story.slug}`">
          <span class="cat">{{ story.subtitle }}</span>
          <span class="title">{{ story.leadTitle }}</span>
          <img v-if="story.previewImage" :src="story.previewImage.url" />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Infoanlass',
  mounted() {},
  computed: {
    stories() {
      return this.$store.state.stories;
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/_helpers';

.stories {
  padding: 0.8rem;
  padding-top: 1rem;
  box-sizing: border-box;

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0.8rem;

    li {
      grid-column: span 4;
      border: 2px solid black;
    }
    a {
      display: block;
      padding: 0.8rem;
      position: relative;
      box-sizing: border-box;
      height: 100%;

      &:hover {
        .title {
          text-decoration: underline;
        }
        img {
          opacity: 1;
        }
      }
    }
    .title {
      font-size: 1.3rem;
      line-height: 1.1;
      font-weight: 900;
      hyphens: auto;
    }
    .cat {
      display: block;
      font-size: 0.6rem;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      margin-bottom: 0.3rem;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: saturate(0);
      opacity: 0;
      // transition: .5s opacity;
    }
  }

  @include bp-s() {
    padding-top: 3rem;

    &__list {
      li {
        grid-column: span 6;
      }
    }
  }
  @include bp-xs() {
    &__list {
      li {
        grid-column: span 12;
      }
    }
  }
}
</style>