<template>
  <div class="imageslider swiper-container" ref="swiperContainer">
    <a
      href="#"
      @click.prevent
      class="swiper-button swiper-button--next"
      ref="swiperButtonNext"
    ></a>
    <a
      href="#"
      @click.prevent
      class="swiper-button swiper-button--prev"
      ref="swiperButtonPrev"
    ></a>
    <div class="swiper-wrapper">
      <div v-for="img in block.images" :key="img.id" class="swiper-slide">
        <figure>
          <img
            :src="img.image.url"
            :width="img.image.width"
            :height="img.image.height"
          />
          <figcaption v-html="img.caption"></figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);
import 'swiper/swiper-bundle.css';

export default {
  name: 'ImageSlider',
  props: ['block'],
  data: () => {
    return {};
  },
  mounted() {
    this.swiper = new SwiperCore(this.$el, {
      spaceBetween: 20,
      speed: 600,
      navigation: {
        nextEl: this.$refs.swiperButtonNext,
        prevEl: this.$refs.swiperButtonPrev,
      },
    });
  },
  beforeDestroy() {
    this.swiper.destroy();
  },
};
</script>

<style lang="scss">
.imageslider {
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;

  figure {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  figcaption {
    margin-top: 0.3rem;
    font-size: 0.65rem;
    line-height: 1.25;
    padding-right: 5px;

    h3 {
      font-weight: bold;
    }
    a {
      text-decoration: underline;
      text-decoration-color: #fff;
    }
    strong {
      font-weight: bold;
    }

    @include bp-s() {
      padding: 0 0.5rem;
    }
  }
  .swiper-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 2.5rem;
    padding-bottom: 71.5%;
    z-index: 10;
    // background: #aff;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      height: 2rem;
      width: 2rem;
      background-color: #fff;
      border-radius: 99%;
      margin-top: -1rem;
      background-image: url('../assets/icon-arrow-up.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 35%;
      transform: rotate(90deg);
    }
    &--prev {
      left: 0;
      right: auto;
      &::before {
        left: auto;
        right: 0;
        transform: rotate(-90deg);
      }
    }
    &.swiper-button-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    @include bp-s() {
      width: 1.5rem;
      height: 1.5rem;
      right: 0.25rem;

      &::before {
        width: 1.5rem;
        height: 1.5rem;
      }

      &--prev {
        left: 0.25rem;
      }
    }
  }
}
</style>
